import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				ShineMaster
			</title>
			<meta name={"description"} content={"Там, де сяє ваш автомобіль"} />
			<meta property={"og:title"} content={"ShineMaster"} />
			<meta property={"og:description"} content={"Там, де сяє ваш автомобіль"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-1.jpg?v=2024-06-14T10:50:58.140Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-1.jpg?v=2024-06-14T10:50:58.140Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-1.jpg?v=2024-06-14T10:50:58.140Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-1.jpg?v=2024-06-14T10:50:58.140Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-1.jpg?v=2024-06-14T10:50:58.140Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-1.jpg?v=2024-06-14T10:50:58.140Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-1.jpg?v=2024-06-14T10:50:58.140Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 0 0" sm-padding="60px 0 60px 0">
			<Box
				padding="80px 72px 80px 72px"
				background="linear-gradient(0deg,rgba(180, 173, 173, 0.38) 0%,#e8e4f4 99%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-1.jpg?v=2024-06-14T10:50:58.140Z) center/cover no-repeat scroll padding-box"
				border-radius="24px"
				margin="0px 0px 64px 0px"
				sm-padding="60px 36px 60px 36px"
			>
				<Box
					width="85%"
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Ознайомтеся з нашими послугами
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--greyD3" md-text-align="center">
						У автомийці ShineMaster ми не просто миємо ваш автомобіль – ми забезпечуємо комплексний режим догляду, який гарантує, що ваш автомобіль буде не тільки чистим, але й захищеним і доглянутим. Наші передові технології та високоякісні продукти задовольняють усі аспекти догляду за автомобілем, гарантуючи, що ваш автомобіль залишає наші приміщення виглядаючи та відчуваючи себе як новий.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text font="--headline3" color="--darkL2" max-width="600px">
					ShineMaster пропонує ряд спеціалізованих послуг, розроблених для задоволення потреб кожного автолюбителя та щоденного водія. Відкрийте для себе досконалість, яку ми пропонуємо:
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="center"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-4.jpg?v=2024-06-14T10:50:58.126Z"
						border-radius="24px"
						max-width="100%"
						max-height="600px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
						align-self="center"
						srcSet="https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-4.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-4.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-4.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-4.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-4.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-4.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-4.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Комплексні рішення для очищення
					</Text>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Зовнішнє миття: Наша передова технологія безконтактного миття ретельно видаляє бруд і пил без ризику подряпин або пошкодження фарби.
Мийка ходової частини: Ретельно вимиває сіль і сміття, необхідне для запобігання іржі та продовження терміну служби вашого автомобіля.
Очищення коліс: Бореться з гальмівним пилом і дорожнім гудроном, повертаючи дискам їхній первісний блиск і захищаючи від майбутніх пошкоджень.
Деталі інтер’єру: Глибоке очищення, яке омолоджує салон вашого автомобіля від панелі приладів до багажника.
Глибокий вакуум: Ретельне очищення під сидіннями та всередині невеликих щілин, щоб усунути всі сліди бруду та сміття.
Очищення оббивки: Використовує спеціальні засоби для видалення плям і відновлення оригінальної текстури та зовнішнього вигляду ваших сидінь.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="center"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-3.jpg?v=2024-06-14T10:50:58.127Z"
						border-radius="24px"
						max-width="100%"
						max-height="600px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
						object-position="70% 50%"
						align-self="center"
						srcSet="https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-3.jpg?v=2024-06-14T10%3A50%3A58.127Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-3.jpg?v=2024-06-14T10%3A50%3A58.127Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-3.jpg?v=2024-06-14T10%3A50%3A58.127Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-3.jpg?v=2024-06-14T10%3A50%3A58.127Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-3.jpg?v=2024-06-14T10%3A50%3A58.127Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-3.jpg?v=2024-06-14T10%3A50%3A58.127Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-3.jpg?v=2024-06-14T10%3A50%3A58.127Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box
						padding="0px 50px 0px 0px"
						lg-width="70%"
						lg-padding="0px 0px 0px 0px"
						sm-width="100%"
						align-self="center"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Спеціалізовані процедури
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Віск і поліроль: Ми наносимо віск преміум-класу, щоб надати вашому автомобілю глянцевого захисного покриття, яке запобігає шкоді навколишньому середовищу.
Захист фарби: Створює бар’єр проти подряпин, УФ-променів і забруднень.
Керамічне покриття: Забезпечує чудовий захисний шар для підвищеної довговічності та блиску.
Очищення двигуна: Оптимізує продуктивність вашого двигуна шляхом видалення накопичень і нанесення захисних покриттів.
Знежирення: Усуває масло та бруд, гарантуючи, що ваш двигун працює більш холодно та ефективніше.
Захисне покриття: Захищає компоненти двигуна від корозії та зносу.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="center"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-3.jpg?v=2024-06-14T10:50:58.126Z"
						border-radius="24px"
						max-width="100%"
						max-height="600px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
						srcSet="https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-3.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-3.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-3.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-3.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-3.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-3.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-3.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Деталізація та реставрація
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Відновлення фар: Покращує видимість і зовнішній вигляд шляхом відновлення помутнілих і пожовклих фар.
Усунення запаху: Використовує передові методи для остаточного видалення запаху, залишаючи салон вашого автомобіля свіжим.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				/>
			</LinkBox>
		</Section>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-1.jpg?v=2024-06-14T10:50:58.141Z) center/cover"
			padding="140px 0 140px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
						Відкрийте для себе повний спектр наших пропозицій
					</Text>
					<Text as="h1" font="--headline3" md-font="--headline2" margin="10px 0">
						Цей огляд – лише початок. Завітайте до нас або зв’яжіться з нашою командою, щоб ознайомитися з повним спектром послуг, які ми пропонуємо, і дізнатися, як ми можемо покращити турботу про ваш автомобіль.
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac377f0cb1d0025f631c9"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});